// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-status_heFky{display:flex}.referral-program-status__content_fOWuB{display:inline-flex;gap:8px;align-items:center}.referral-program-status__content--pending_IX7pd .referral-program-status__round-container_zJ4Xi{background-color:var(--Blue)}.referral-program-status__content--pending_IX7pd .referral-program-status__label_daDB8{color:var(--Blue)}.referral-program-status__content--pending_IX7pd .referral-program-status__icon_dWhja{fill:var(--BrandText)}.referral-program-status__content--available_LIShu .referral-program-status__round-container_zJ4Xi{background-color:#fff}.referral-program-status__content--available_LIShu .referral-program-status__label_daDB8{color:var(--BrandDefault)}.referral-program-status__content--available_LIShu .referral-program-status__icon_dWhja{fill:var(--BrandDefault)}.referral-program-status__content--completed_FqN8y .referral-program-status__round-container_zJ4Xi{color:var(--BrandText)}.referral-program-status__round-container_zJ4Xi{display:flex;align-items:center;justify-content:center;width:22px;height:22px;background-color:var(--BrandDefault);border:1px solid var(--Layer1);border-radius:50%}.referral-program-status__label_daDB8{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-status": `referral-program-status_heFky`,
	"referral-program-status__content": `referral-program-status__content_fOWuB`,
	"referral-program-status__content--pending": `referral-program-status__content--pending_IX7pd`,
	"referral-program-status__round-container": `referral-program-status__round-container_zJ4Xi`,
	"referral-program-status__label": `referral-program-status__label_daDB8`,
	"referral-program-status__icon": `referral-program-status__icon_dWhja`,
	"referral-program-status__content--available": `referral-program-status__content--available_LIShu`,
	"referral-program-status__content--completed": `referral-program-status__content--completed_FqN8y`
};
export default ___CSS_LOADER_EXPORT___;
