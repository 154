// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-sharing-copy-link_AWTqE{display:inline-flex;flex-direction:column;row-gap:12px;width:100%}.social-sharing-copy-link__label_fFVnL{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.social-sharing-copy-link__block_N6tUo{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;display:flex;column-gap:8px;align-items:center;justify-content:space-between;width:100%;height:44px;padding-left:16px;overflow:hidden;color:var(--TextDefault);cursor:pointer;background-color:var(--Layer2);border-radius:var(--BorderRadius)}.social-sharing-copy-link__block_N6tUo:focus{box-shadow:inset 0 0 0 1px var(--Blue)}.social-sharing-copy-link__text_eBaPE{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.social-sharing-copy-link__icon_P1rcW{display:flex;flex-shrink:0;align-items:center;justify-content:center;width:52px;height:100%;color:var(--BrandText);background-color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-sharing-copy-link": `social-sharing-copy-link_AWTqE`,
	"social-sharing-copy-link__label": `social-sharing-copy-link__label_fFVnL`,
	"social-sharing-copy-link__block": `social-sharing-copy-link__block_N6tUo`,
	"social-sharing-copy-link__text": `social-sharing-copy-link__text_eBaPE`,
	"social-sharing-copy-link__icon": `social-sharing-copy-link__icon_P1rcW`
};
export default ___CSS_LOADER_EXPORT___;
