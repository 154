// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-block_f9l64{display:flex;justify-content:space-between;width:100%;height:56px;padding:8px 12px;background-color:var(--Layer1);border-radius:5px}.referral-program-block--clickable_GbfR7{cursor:pointer}.referral-program-block__labels_rDOi6{display:flex;flex-direction:column}.referral-program-block__title_kxqse{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextPrimary)}.referral-program-block__value_MvVEC{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;color:var(--TextDefault)}.referral-program-block__icon_sbI6r{fill:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-block": `referral-program-block_f9l64`,
	"referral-program-block--clickable": `referral-program-block--clickable_GbfR7`,
	"referral-program-block__labels": `referral-program-block__labels_rDOi6`,
	"referral-program-block__title": `referral-program-block__title_kxqse`,
	"referral-program-block__value": `referral-program-block__value_MvVEC`,
	"referral-program-block__icon": `referral-program-block__icon_sbI6r`
};
export default ___CSS_LOADER_EXPORT___;
