// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-member-item_6fSd4{display:flex;align-items:center;justify-content:space-between;width:100%;padding:12px;cursor:pointer;background-color:var(--Layer1);border-radius:0}.referral-program-member-item__left_ptdle{display:flex;gap:12px;align-items:center}.referral-program-member-item__right_e2l44{display:flex;flex-direction:column;gap:8px;align-items:flex-end}.referral-program-member-item__amount_gAUMo{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;height:16px;color:var(--TextDefault)}.referral-program-member-item__amount--bonus_PYUSf{color:var(--BrandDefault)}.referral-program-member-item__date_YAw9B{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal;color:var(--TextSecondary)}.referral-program-member-item__label_JbzIP{display:flex;flex-direction:column;gap:8px}.referral-program-member-item__member-name_DYJL6{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextDefault)}.referral-program-member-item__member-status_zeBlx{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal}.referral-program-member-item__member-status--completed_U_Vwl{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-member-item": `referral-program-member-item_6fSd4`,
	"referral-program-member-item__left": `referral-program-member-item__left_ptdle`,
	"referral-program-member-item__right": `referral-program-member-item__right_e2l44`,
	"referral-program-member-item__amount": `referral-program-member-item__amount_gAUMo`,
	"referral-program-member-item__amount--bonus": `referral-program-member-item__amount--bonus_PYUSf`,
	"referral-program-member-item__date": `referral-program-member-item__date_YAw9B`,
	"referral-program-member-item__label": `referral-program-member-item__label_JbzIP`,
	"referral-program-member-item__member-name": `referral-program-member-item__member-name_DYJL6`,
	"referral-program-member-item__member-status": `referral-program-member-item__member-status_zeBlx`,
	"referral-program-member-item__member-status--completed": `referral-program-member-item__member-status--completed_U_Vwl`
};
export default ___CSS_LOADER_EXPORT___;
