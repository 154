import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { useVSocialSharingCopyLink } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSocialSharingCopyLink',
    props: {
        label: {},
        link: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { iconCopyProps } = useVSocialSharingCopyLink();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['social-sharing-copy-link'])
            }, [
                _ctx.label ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['social-sharing-copy-link__label'])
                }, _toDisplayString(_ctx.label), 3)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['social-sharing-copy-link__block']),
                    onClick: _cache[0] || (_cache[0] = ($event)=>emit('click'))
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['social-sharing-copy-link__text'])
                    }, _toDisplayString(_ctx.link), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['social-sharing-copy-link__icon'])
                    }, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconCopyProps))), null, 16)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSocialSharingCopyLink'
                ]
            ]);
        };
    }
});
