// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-member-list_C7w8s{padding:0 0 64px}.referral-program-member-list__item-body_P69sc{gap:1px;display:flex;flex-direction:column;overflow:hidden;border-radius:var(--BorderRadius)}.referral-program-member-list__item-title_D5ymg{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;justify-content:space-between;padding:16px 8px 12px;margin:0;color:var(--TextSecondary)}html.app__layout--desktop .referral-program-member-list__item--flexible_ga3nR .referral-program-member-list__item-title_D5ymg{cursor:pointer}.referral-program-member-list__item--flexible_ga3nR .referral-program-member-list__item-container_isvFz{overflow:hidden}.referral-program-member-list__item_hpKxK .referral-program-member-list__item-toggle_OO8Ep{transform:rotate(180deg)}.referral-program-member-list__item--open_uBveI .referral-program-member-list__item-toggle_OO8Ep{transform:rotate(0deg)}.referral-program-member-list__item--animated_lT_8P.referral-program-member-list__item--flexible_ga3nR .referral-program-member-list__item-container_isvFz{transition:height .4s ease-out}.referral-program-member-list__item--animated_lT_8P .referral-program-member-list__item-toggle_OO8Ep{transition:.4s ease-out}.referral-program-member-list__item--animated_lT_8P.referral-program-member-list__item--open_uBveI .referral-program-member-list__item-toggle_OO8Ep{transition:.6s ease-out}.referral-program-member-list__item--animated_lT_8P.referral-program-member-list__item--open_uBveI .referral-program-member-list__item-container_isvFz{transition:height .6s ease-out}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-member-list": `referral-program-member-list_C7w8s`,
	"referral-program-member-list__item-body": `referral-program-member-list__item-body_P69sc`,
	"referral-program-member-list__item-title": `referral-program-member-list__item-title_D5ymg`,
	"referral-program-member-list__item--flexible": `referral-program-member-list__item--flexible_ga3nR`,
	"referral-program-member-list__item-container": `referral-program-member-list__item-container_isvFz`,
	"referral-program-member-list__item": `referral-program-member-list__item_hpKxK`,
	"referral-program-member-list__item-toggle": `referral-program-member-list__item-toggle_OO8Ep`,
	"referral-program-member-list__item--open": `referral-program-member-list__item--open_uBveI`,
	"referral-program-member-list__item--animated": `referral-program-member-list__item--animated_lT_8P`
};
export default ___CSS_LOADER_EXPORT___;
