// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-select-bonus_mWLnc{width:100%;background-color:var(--Layer1)}.referral-program-select-bonus__top-block_GFcxx{display:flex;justify-content:space-between;padding:12px 12px 12px 0}.referral-program-select-bonus__column_hX24d{display:flex;color:var(--TextDefault)}.referral-program-select-bonus__label_j7OoM{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;margin:0 0 4px}.referral-program-select-bonus__count_Dw3Sa{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal}.referral-program-select-bonus__bottom-block_dOCd7{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;padding:0 12px 12px;color:var(--TextPrimary)}.referral-program-select-bonus_mWLnc .referral-program-select-bonus__bottom-container_qD5Th{overflow:hidden;transition:height .4s ease-out}.referral-program-select-bonus__bottom-label_OywtE{opacity:0;transition:opacity .4s ease-out}.referral-program-select-bonus--open_SxJLM{background-color:var(--Layer1)}.referral-program-select-bonus--open_SxJLM .referral-program-select-bonus__bottom-container_qD5Th{transition:height .6s ease-out}.referral-program-select-bonus--open_SxJLM .referral-program-select-bonus__bottom-label_OywtE{opacity:1;transition:opacity .4s ease-out}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-select-bonus": `referral-program-select-bonus_mWLnc`,
	"referral-program-select-bonus__top-block": `referral-program-select-bonus__top-block_GFcxx`,
	"referral-program-select-bonus__column": `referral-program-select-bonus__column_hX24d`,
	"referral-program-select-bonus__label": `referral-program-select-bonus__label_j7OoM`,
	"referral-program-select-bonus__count": `referral-program-select-bonus__count_Dw3Sa`,
	"referral-program-select-bonus__bottom-block": `referral-program-select-bonus__bottom-block_dOCd7`,
	"referral-program-select-bonus__bottom-container": `referral-program-select-bonus__bottom-container_qD5Th`,
	"referral-program-select-bonus__bottom-label": `referral-program-select-bonus__bottom-label_OywtE`,
	"referral-program-select-bonus--open": `referral-program-select-bonus--open_SxJLM`
};
export default ___CSS_LOADER_EXPORT___;
