// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-sharing-item_vKXhL{display:inline-flex;flex-direction:column;gap:4px;align-items:center;-webkit-text-decoration:none;text-decoration:none}@media(hover:hover)and (pointer:fine){.social-sharing-item_vKXhL:hover{cursor:pointer}}.social-sharing-item__icon_OaIZS{display:flex;align-items:center;justify-content:center;width:52px;height:52px;color:var(--BrandText);border:1px solid #0000;border-radius:50%}.social-sharing-item__name_gN_7b{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);text-align:center}.social-sharing-item__name_gN_7b:first-letter{text-transform:uppercase}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-sharing-item": `social-sharing-item_vKXhL`,
	"social-sharing-item__icon": `social-sharing-item__icon_OaIZS`,
	"social-sharing-item__name": `social-sharing-item__name_gN_7b`
};
export default ___CSS_LOADER_EXPORT___;
