// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .referral-program__wrapper_JcUgB{position:relative;display:flex;flex-direction:column;align-items:flex-start;align-self:stretch;justify-content:center;height:148px;padding:32px 16px;overflow:hidden;cursor:pointer;background-color:var(--Highlight);border-radius:5px}html.app__layout--desktop .referral-program__text_iyr30{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;z-index:1;width:160px;color:var(--TextDefault)}html.app__layout--desktop .referral-program__image_ryhd_{right:-44px;width:218px;height:158px;position:absolute;bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program__wrapper": `referral-program__wrapper_JcUgB`,
	"referral-program__text": `referral-program__text_iyr30`,
	"referral-program__image": `referral-program__image_ryhd_`
};
export default ___CSS_LOADER_EXPORT___;
