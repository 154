// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-top-bar__info-button_rBPfB{width:56px;height:56px;color:var(--ColorContent2);background:#0000;border:none}@media(hover:hover)and (pointer:fine){html.app__layout--desktop .referral-program-top-bar__info-button_rBPfB{cursor:pointer}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-top-bar__info-button": `referral-program-top-bar__info-button_rBPfB`
};
export default ___CSS_LOADER_EXPORT___;
