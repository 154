// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-add-button_Yy7xW{display:flex;flex-direction:column;gap:4px;align-items:center;width:60px}.referral-program-add-button__circle_UBKiT{display:flex;align-items:center;justify-content:center;width:60px;height:60px;background-color:var(--OpacityBrandDefault);border-radius:50%}.referral-program-add-button__icon_rxjR2{color:var(--BrandDefault)}.referral-program-add-button__label_Tx2CB{font-size:10px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--BrandDefault);width:60px;text-align:center}@media(hover:hover)and (pointer:fine){.referral-program-add-button_Yy7xW:hover{cursor:pointer}.referral-program-add-button_Yy7xW:hover .referral-program-add-button__circle_UBKiT{background-color:var(--OpacityBrandHighlight)}.referral-program-add-button_Yy7xW:hover .referral-program-add-button__icon_rxjR2{color:var(--BrandHighlight)}.referral-program-add-button_Yy7xW:hover .referral-program-add-button__label_Tx2CB{color:var(--BrandDefault)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-add-button": `referral-program-add-button_Yy7xW`,
	"referral-program-add-button__circle": `referral-program-add-button__circle_UBKiT`,
	"referral-program-add-button__icon": `referral-program-add-button__icon_rxjR2`,
	"referral-program-add-button__label": `referral-program-add-button__label_Tx2CB`
};
export default ___CSS_LOADER_EXPORT___;
