// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .social-sharing__content_kAHBn{display:flex;flex-direction:column;margin:0}html.app__layout--desktop .social-sharing__title_G5xqn{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:12px;color:var(--TextDefault);text-align:center}html.app__layout--desktop html.app__layout--desktop .social-sharing__title_G5xqn{margin-top:-16px}html.app__layout--desktop .social-sharing__header_ml6P4{border-top-left-radius:15px;border-top-right-radius:15px}html.app__layout--desktop .social-sharing__slider_mjQ7z{margin-bottom:20px}html.app__layout--desktop .social-sharing__network-item_XqdEJ{margin:0 12px}html.app__layout--desktop .social-sharing__slide_rMYT7{flex:1}html.app__layout--desktop .social-sharing__slide_rMYT7:first-child .social-sharing__network-item_XqdEJ{margin-left:0}html.app__layout--desktop .social-sharing__footer_IdZ0b{margin:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-sharing__content": `social-sharing__content_kAHBn`,
	"social-sharing__title": `social-sharing__title_G5xqn`,
	"social-sharing__header": `social-sharing__header_ml6P4`,
	"social-sharing__slider": `social-sharing__slider_mjQ7z`,
	"social-sharing__network-item": `social-sharing__network-item_XqdEJ`,
	"social-sharing__slide": `social-sharing__slide_rMYT7`,
	"social-sharing__footer": `social-sharing__footer_IdZ0b`
};
export default ___CSS_LOADER_EXPORT___;
